/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react';
import Bubble from '../../components/Bubble/Bubble';
import EmptyGridContent from '../../components/EmptyGridContent/EmptyGridContent';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectAccreditationDashboardItems,
  selectAccreditationLoading,
  setEditCountSorted,
} from '../../redux/slices/accreditationSlice';
import { fetchAccreditationEditsCount } from '../../redux/thunks/accreditationThunks';
import { abortPromiseOnUnmount } from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { AccreditationDashboardModel } from '../../types/AccreditationDashboardModel';
import { IconType } from '../../types/propTypes/EmptyGridPropTypes';
import './Accreditation.css';

const Accreditation = (): ReactElement => {
  const isLoading = useAppSelector(selectAccreditationLoading);
  const dashboardItems: AccreditationDashboardModel[] = useAppSelector(
    selectAccreditationDashboardItems
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const promise: unknown = dispatch(fetchAccreditationEditsCount());
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(setEditCountSorted());
  }, [dispatch, dashboardItems]);

  return (
    <NavigationPage
      pageClass="accreditation"
      heading={`Accreditation Edits`}
      isLoading={isLoading}
      loadingDataId="accreditation"
      loadingText="Getting your items"
    >
      {dashboardItems.length
        ? dashboardItems.map(
            (item, index) =>
              +item.editCount !== 0 && (
                <Bubble
                  key={index}
                  count={item.editCount}
                  title={item.domain}
                  titleTransform={
                    item.domain == 'lea' ? 'uppercase' : 'capitalize'
                  }
                  bubbleText="Edits"
                  navigateTo={appRoutePaths.AccreditationEdits(item.domain)}
                  visibilityOverrideRoute={appRoutePaths.Accreditation}
                />
              )
          )
        : null}
      {dashboardItems.length == 0 && (
        <EmptyGridContent iconType={IconType.FILE}>
          <p>You currently have no edits.</p>
        </EmptyGridContent>
      )}
    </NavigationPage>
  );
};

export default Accreditation;
