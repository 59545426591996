import { VALIDATION_MESSAGE } from '../ValidationMessage';
import yup from './CustomValidation/yup-extended';

export const SalaryScaleValidationSchema = yup.object().shape({
  additionalSalary: yup
    .string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .matches(
      /^(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      VALIDATION_MESSAGE.MUST_BE_NUMBER_FORMAT
    )
    .notOneOf(
      ['0.00', '0.0', '0', '.00', '.0', '$0.00', '$0.0', '$0', '$.00', '$.0'],
      VALIDATION_MESSAGE.IS_EMPTY
    ),
});
